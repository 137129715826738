import { App, Button, Card, Form, Input, InputNumber, Select, Space, Typography, Upload } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FlexBox from '../../common/FlexBox.tsx';
import { translate, translateUnit } from '../../../translations/TranslationUtils.ts';
import { InboxOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUnitsQuery } from '../../../persistence/productApiSlice.ts';
import {
  complaintApiSlice,
  useAddComplaintFileMutation,
  useDeleteComplaintFileMutation,
  useGetComplaintFilesQuery,
  useLazyGetComplaintProductionQuery,
  useSaveComplaintMutation,
  useSubmitComplaintMutation,
} from '../../../persistence/complaintApiSlice.ts';
import './ComplaintForm.css';
import Image from '../../../assets/img/complaint-example.svg';
import { Complaint, ComplaintFile, ComplaintFileUpload, ComplaintStatus, SaveComplaintRequest } from '../../../persistence/model/Complaint.ts';
import ComplaintDelete from './ComplaintDelete.tsx';
import config from '../../../config.ts';
import { useUploadFileMutation } from '../../../persistence/fileUploadApi.ts';
import CustomerSelect from '../../common/customer-select/CustomerSelect.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, selectCurrentUserIsEstiko } from '../../../persistence/authSlice.ts';

type ComplaintFields = {
  productionId?: string;
  itemCode?: string;
  quantity?: number;
  unit?: string;
  unitNumber?: string;
  invoiceNumber?: string;
  purchaseOrderNum?: string;
  productionNumber?: string;
  content?: string;
  claim?: string;
};

type ComplaintFormProps = {
  complaint: Complaint;
};

const ComplaintForm = ({ complaint }: ComplaintFormProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm<ComplaintFields>();
  const { data: complaintFiles } = useGetComplaintFilesQuery(complaint.id, { skip: !complaint.id });
  const [saveRequest, { isLoading: saving }] = useSaveComplaintMutation();
  const [getProduction, { isLoading: productionLoading }] = useLazyGetComplaintProductionQuery();
  const [submitRequest, { isLoading: submitting }] = useSubmitComplaintMutation();
  const [uploadFileRequest] = useUploadFileMutation();
  const [deleteFileRequest] = useDeleteComplaintFileMutation();
  const [addComplaintFileRequest] = useAddComplaintFileMutation();
  const { data: units, isLoading: unitsLoading } = useGetUnitsQuery();
  const isLoading = useMemo(() => saving || submitting, [saving, submitting]);
  const showDeleteButton = useMemo(() => complaint.id && complaint.status === ComplaintStatus.DRAFT, [complaint.id, complaint.status]);
  const [customerId, setCustomerId] = useState<number>(complaint.customerId);
  const [productionSearchInput, setProductionSearchInput] = useState<string>('');
  const user = useSelector(selectCurrentUser);
  const isEstikoUser = useSelector(selectCurrentUserIsEstiko);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    setCustomerId(complaint.customerId);
  }, [complaint]);

  useEffect(() => {
    form.setFieldsValue({
      productionId: complaint.productionId,
      itemCode: complaint.itemCode,
      quantity: complaint.quantity,
      unit: complaint.unit,
      productionNumber: complaint.roll,
      content: complaint.complaintContent,
      claim: complaint.complaintClaim,
      invoiceNumber: complaint.invoiceNumber,
      purchaseOrderNum: complaint.purchaseOrderNum,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (complaintFiles?.data) {
      form.setFieldValue(
        'attachments',
        complaintFiles.data.map(
          (file: ComplaintFile) =>
            ({
              fileId: file.id,
              name: file.fileName,
              fileName: file.fileName,
              filePath: file.filePath,
              uid: `${file.id}`,
              status: 'done',
              url: 'mock',
            }) as ComplaintFileUpload,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaintFiles?.data]);

  const saveComplaint = useCallback(
    async (submit?: boolean) => {
      return Promise.resolve(form.getFieldsValue())
        .then(async (data) =>
          saveRequest({
            contactUserId: isEstikoUser ? complaint.contactUserId : user.userId,
            complaintId: complaint.id,
            customerId: customerId,
            quantity: data.quantity,
            unit: data.unit,
            roll: data.unitNumber,
            complaintContent: data.content,
            complaintClaim: data.claim,
            files: form.getFieldValue('attachments').map((f: ComplaintFileUpload) => ({
              fileName: f.fileName,
              filePath: f.filePath,
            })),
            invoiceNum: data.invoiceNumber,
            productionId: data.productionNumber,
            itemCode: data.itemCode,
            purchaseOrderNum: data.purchaseOrderNum,
          } as SaveComplaintRequest).unwrap(),
        )
        .then((savedComplaint) => {
          navigate(`/complaint/${savedComplaint.data.id}`);
          if (submit) {
            return form
              .validateFields()
              .then(() => submitRequest(savedComplaint.data.id).unwrap())
              .then(() => savedComplaint)
              .catch(() => message.error(translate(intl, 'complaint.message.validationFailed')))
          } else {
            return Promise.resolve(savedComplaint);
          }
        })
        .then(() => {
          message.success(translate(intl, `complaint.message.${submit ? 'submitSuccess' : 'saveSuccess'}`));
        })
        .catch((e) => {
          if (!e.errorFields) {
            message.error(translate(intl, `complaint.message.${submit ? 'submitFailed' : 'saveFailed'}`));
          }
        });
    },
    [form, saveRequest, isEstikoUser, complaint.contactUserId, complaint.id, user.userId, customerId, navigate, submitRequest, message, intl],
  );

  const getProductionFields = useCallback(async () => {
    return Promise.resolve(form.getFieldValue('productionNumber'))
      .then(async (productionId) => getProduction({complaintId: complaint.id, productionId: productionId}).unwrap()
        .then((response) => form.setFieldsValue({
          itemCode: response.data.itemNo,
          invoiceNumber: response?.data.invoiceNo?.trim(),
          purchaseOrderNum: response.data.salesOrderNo.trim(),
        }))
        .then(() => message.success(translate(intl, 'complaint.message.productionSearch.success')))
        .catch(() => message.warning(translate(intl, 'complaint.message.productionSearch.failed'))));
  }, [form, getProduction, complaint.id, message, intl]);

  const searchProductionButton = useCallback(() => (
    <Button disabled={!productionSearchInput.length} loading={productionLoading} style={{ alignSelf: 'end', marginBottom: 14, marginLeft: 10 }}
            onClick={() => getProductionFields()}>
      <FormattedMessage id={'common.searchPlaceholder'} />
    </Button>
  ), [getProductionFields, productionSearchInput, productionLoading]);

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="complaint.edit.title" />
      </Typography.Title>
      <FlexBox direction="vertical">
        <Form layout="vertical" form={form}>
          <div className="complaint-form-1">
            <div style={{ display: 'flex', flex: '1 1 300px', flexWrap: 'nowrap', borderRight: '1px solid #E4E4E4' }}>
              <Form.Item name="productionNumber" style={{ flex: '1' }} label={translate(intl, 'complaint.edit.productionNumber')} rules={[{ required: true }]}>
                <Input placeholder={translate(intl, 'complaint.edit.productionNumberPlaceholder')} onChange={(e) => setProductionSearchInput(e.target.value)} />
              </Form.Item>
              {searchProductionButton()}
            </div>
            <Form.Item name="invoiceNumber" style={{ flex: '1 1 300px' }} label={translate(intl, 'complaint.edit.invoiceNumber')}>
              <Input placeholder={translate(intl, 'complaint.edit.invoiceNumber')} />
            </Form.Item>
            <Form.Item name="purchaseOrderNum" style={{ flex: '1 1 300px' }} label={translate(intl, 'complaint.edit.orderNumber')}>
              <Input placeholder={translate(intl, 'complaint.edit.orderNumber')} />
            </Form.Item>
          </div>
          <Typography.Title level={4}>
            <FormattedMessage id="complaint.edit.details" />
          </Typography.Title>
          <Card>
            <FlexBox direction={'horizontal'} style={{ justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
              <FlexBox direction={'vertical'} style={{ flex: '3 1 700px' }}>
                <FlexBox direction={'horizontal'}>
                  <Form.Item name="itemCode" label={translate(intl, 'complaint.edit.itemCode')} rules={[{ required: true, min: 7 }]} style={{ flex: 1 }}>
                    <Input />
                  </Form.Item>
                  <Space.Compact style={{ display: 'flex', flex: 1 }}>
                    <Form.Item
                      name="quantity"
                      rules={[{ type: 'number' }]}
                      className="complaint-quantity"
                      label={translate(intl, 'complaint.edit.quantity')}
                    >
                      <InputNumber placeholder={translate(intl, 'complaint.edit.quantityPlaceholder')} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      name="unit"
                      label={translate(intl, 'complaint.edit.unit')}
                      help={false}
                      className="complaint-unit"
                    >
                      <Select
                        loading={unitsLoading}
                        style={{ minWidth: '70px' }}
                        options={units?.data?.map((unit) => ({ label: translateUnit(intl, unit), value: unit })) || []}
                      />
                    </Form.Item>
                  </Space.Compact>
                  <Form.Item name="unitNumber" label={translate(intl, 'complaint.edit.unitNumber')} style={{ flex: 1 }}>
                    <Input placeholder={translate(intl, 'complaint.edit.unitNoPlaceholder')} />
                  </Form.Item>
                </FlexBox>
                <Form.Item name="content" label={translate(intl, 'complaint.edit.content')} rules={[{ required: true }]}>
                  <Input.TextArea rows={5} placeholder={translate(intl, 'complaint.edit.contentPlaceholder')} />
                </Form.Item>
                <Form.Item name="claim" label={translate(intl, 'complaint.edit.claim')} rules={[{ required: true }]}>
                  <Input.TextArea rows={5} placeholder={translate(intl, 'complaint.edit.claimPlaceholder')} />
                </Form.Item>
                <Form.Item
                  required={true}
                  name="attachments"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
                  label={translate(intl, 'complaint.edit.attachments')}
                  rules={[
                    {
                      validator: (_, value) => {
                        const files: ComplaintFileUpload[] = Array.isArray(value) ? value : value?.fileList || [];
                        const allUploadsSucceeded = files.every(file => (file.status === 'done' && file.fileId) || (file.filePath && file.fileName));

                        if ((value instanceof Array ? value : value?.fileList)?.length > 0) {
                          if (allUploadsSucceeded) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(translate(intl, 'complaint.edit.attachmentsError')));
                        }

                        return Promise.reject(new Error(translate(intl, 'complaint.edit.noAttachments')));
                      },
                    },
                  ]}
                >
                  <Upload.Dragger
                    onDownload={(file) => {
                      // @ts-ignore
                      dispatch(complaintApiSlice.util.prefetch('getComplaintFileContent', { complaintId: complaint.id, fileId: file.uid }, { force: true }));
                    }}
                    onPreview={(file) => {
                      // @ts-ignore
                      dispatch(complaintApiSlice.util.prefetch('getComplaintFileContent', { complaintId: complaint.id, fileId: file.uid }, { force: true }));
                    }}
                    beforeUpload={async (file: ComplaintFileUpload) => {
                      const ALLOWED_EXTENSIONS = Array.from(config.COMPLAIN_ATTACHMENT_EXTENSIONS);
                      const MAX_FILE_SIZE_MB = 10;

                      // Check file size
                      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
                        message.warning(
                          translate(intl, 'complaint.edit.fileTooLarge', { size: MAX_FILE_SIZE_MB }),
                          10
                        );
                        return Upload.LIST_IGNORE;
                      }

                      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
                      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
                        message.warning(translate(intl, 'complaint.edit.invalidFileType', { types: ALLOWED_EXTENSIONS.join(', ')}), 15);
                        return Upload.LIST_IGNORE;
                      }

                      await uploadFileRequest(file as any)
                        .unwrap()
                        .then(async (response) => {
                          file.filePath = response.filePath;
                          file.fileName = response.fileName;

                          await addComplaintFileRequest({
                            complaintId: complaint.id,
                            fileName: response.fileName,
                            filePath: response.filePath,
                          }).unwrap();
                        });
                      return false;
                    }}
                    onRemove={async (file: ComplaintFileUpload) => {
                      if (file.fileId) {
                        await deleteFileRequest({ complaintId: complaint.id, fileId: file.fileId });
                      }
                    }}
                    accept={Array.from(config.COMPLAIN_ATTACHMENT_EXTENSIONS).join()}
                    className={'drag-and-drop'}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      <FormattedMessage id="complaint.edit.dropAttachments" />
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </FlexBox>
              <FlexBox direction={'vertical'} style={{ flex: '1 1 300px', justifyContent: 'start', alignItems: 'baseline' }}>
                <img src={Image} style={{ width: '100%', maxWidth: '500px', alignSelf: 'center' }} alt="example" />
              </FlexBox>
            </FlexBox>
          </Card>
          <FlexBox direction={'horizontal'} style={{ justifyContent: 'end', padding: '20px 0' }}>
            {showDeleteButton && <ComplaintDelete complaint={complaint} disabled={isLoading} />}
            <Button className="uppercase" loading={isLoading} disabled={isLoading} onClick={() => saveComplaint()}>
              <FormattedMessage id="complaint.edit.save" />
            </Button>
            <Button type="primary" className="uppercase" loading={isLoading} disabled={isLoading} onClick={() => saveComplaint(true)}>
              <FormattedMessage id="complaint.edit.send" />
            </Button>
          </FlexBox>
        </Form>
      </FlexBox>
      <CustomerSelect
        isOpen={!customerId}
        preselectedCustomerId={customerId}
        onCustomerSelect={async (id) => setCustomerId(id)}
        onClose={() => !customerId && navigate('/complaints')}
      />
    </>
  );
};

export default ComplaintForm;
