import { useMemo } from 'react';
import { translate, translateUnit } from '../../../translations/TranslationUtils.ts';
import { ColumnType } from 'antd/es/table';
import { SalesOrderLiveRow } from '../../../persistence/model/Order.ts';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { formatDate } from '../../../util/DateUtil.ts';
import { formatPortalNumber } from '../../../util/NumberUtil.ts';
import './OrderCallOffRows.css';
import dayjs from 'dayjs';

type OrderCallOffRowsProps = {
  data: SalesOrderLiveRow[];
  colspanWidth: number;
};

const OrderCallOffRows = ({ data, colspanWidth }: OrderCallOffRowsProps): JSX.Element => {
  const intl = useIntl();
  const columns = useMemo(
    () =>
      [
        {
          key: 'skip',
          width: colspanWidth,
        },
        {
          key: 'deliveryDate',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.deliveryDate'),
          dataIndex: 'deliveryDate',
          render: (value) => value && formatDate(intl, dayjs(value)),
        },
        {
          key: 'requestedQuantity',
          title: translate(intl, 'order.details.quotationRows.calledOffQuantity'),
          dataIndex: 'requestedQuantity',
          render: (value, record) => `${formatPortalNumber(value)} ${translateUnit(intl, record.unit)}`,
        },
        {
          key: 'quantityDelivered',
          title: translate(intl, 'order.details.quotationRows.delivered'),
          dataIndex: 'quantityDelivered',
          render: (value, record) => `${formatPortalNumber(value)} ${translateUnit(intl, record.unit)}`,
        },
        {
          key: 'unitPrice',
          title: translate(intl, 'order.details.quotationRows.unitPrice', { currency: data[0].currency }),
          dataIndex: 'unitPrice',
          render: (_, record) => `${formatPortalNumber( record.requestedQuantity && record.price ? (record.price / record.requestedQuantity) : record.unitPrice , {fractionDigits: 4})}`,
        },
        {
          key: 'price',
          title: translate(intl, 'order.details.quotationRows.price', { currency: data[0].currency }),
          render: (_, record) => `${formatPortalNumber(record.price, { fractionDigits: 2 })}`,
        },
      ] as ColumnType<SalesOrderLiveRow>[],
    [intl, colspanWidth]
  );

  return <Table<SalesOrderLiveRow> className="live-rows" rowKey="invoiceId" columns={columns} pagination={false} dataSource={data || []} />;
};

export default OrderCallOffRows;
