import { Button, Table, Typography } from 'antd';
import { useIntl } from 'react-intl';
import React, { useCallback, useMemo } from 'react';
import { useHealthCheckQuery, useSyncTablesMutation } from '../../../persistence/adminApiSlice.ts';
import { UserListRow } from '../../../persistence/model/User.ts';
import { ColumnType } from 'antd/es/table/interface';
import { formatDateTime } from '../../../util/DateUtil.ts';
import { ReloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../../../persistence/apiSlice.ts';

const PublisherApiPage = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data: healthCheckData, isFetching } = useHealthCheckQuery();
  const [syncTablesQuery, { isLoading: isSyncing }] = useSyncTablesMutation();


  const refreshHealthCheck = useCallback(() => dispatch(apiSlice.util.invalidateTags(['HealthCheck'])), [dispatch]);

  const syncTableButton = useCallback((tableName: string) => (
    <Button
      onClick={() => syncTablesQuery([tableName])}
      disabled={isSyncing}
      loading={isSyncing}
      type={'primary'}
    >SYNC</Button>
  ), [isSyncing, syncTablesQuery]);

  const columns = useMemo(
    () =>
      [
        {
          dataIndex: 'tableName',
          title: 'Table name',
        },
        {
          dataIndex: 'totalCount',
          width: '200px',
          title: 'Total events',
          render: (value) => value || 0,
        },
        {
          dataIndex: 'unprocessedCount',
          width: '200px',
          title: 'Unprocessed events',
          render: (value) => value || 0,
        },
        {
          dataIndex: 'triggerExists',
          width: '200px',
          title: 'Trigger exists',
          render: (value) => value ? 'TRUE' : 'FALSE',
        },
        {
          dataIndex: 'lastEventTs',
          width: '200px',
          title: 'Last event date',
          render: (value) => value ? formatDateTime(intl, value) : null,
        },
        {
          key: 'syncButton',
          width: '110px',
          render: (_, row) => syncTableButton(row.tableName),
        },
      ] as ColumnType<any>[],
    [intl, syncTableButton],
  );

  return (
    <>
      <Typography.Title level={5}>
        <div>
          <span>PUBLISHER API</span>
          <Button
            style={{ justifySelf: 'end', marginLeft: '10px' }}
            icon={<ReloadOutlined style={{ marginRight: 5 }} />}
            onClick={() => refreshHealthCheck()}
            loading={isFetching}
            disabled={isFetching}
            type={'text'}
          />
        </div>
      </Typography.Title>
      <Table<UserListRow>
        columns={columns}
        size="small"
        dataSource={healthCheckData?.tables || []}
        loading={isFetching}
        rowKey="tableName"
        pagination={false}
      />
    </>
  );
};

export default PublisherApiPage;
