import { useMemo } from 'react';
import { SalesOrderProduct } from '../../../persistence/model/Order.ts';
import { translate, translateUnit } from '../../../translations/TranslationUtils.ts';
import { useIntl } from 'react-intl';
import { Card, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { formatDate } from '../../../util/DateUtil.ts';
import { useGetSalesOrderProductsQuery } from '../../../persistence/orderApiSlice.ts';
import { formatCurrency, formatPortalNumber } from '../../../util/NumberUtil.ts';
import dayjs from 'dayjs';
import ProductRenderer from '../../product/ProductRenderer.tsx';

type OrderLiveRowsProps = {
  purchaseOrderId: number;
};

const OrderQuotationRows = ({ purchaseOrderId }: OrderLiveRowsProps): JSX.Element => {
  const intl = useIntl();
  const { data: salesOrderRows, isLoading } = useGetSalesOrderProductsQuery(purchaseOrderId);

  const currency = useMemo(() => {return salesOrderRows?.data ? salesOrderRows?.data[0]?.currency : ''},[isLoading, salesOrderRows])


  const columns = useMemo(
    () =>
      [
        {
          key: 'item',
          title: translate(intl, 'order.details.quotationRows.product'),
          dataIndex: 'productName',
          render: (productName, { productCode, externalItemId, ean }) => (
            <ProductRenderer productNumber={productCode} productName={productName} externalItemId={externalItemId} ean={ean} />
          ),
        },
        {
          key: 'deliveryDate',
          width: 150,
          title: translate(intl, 'order.details.quotationRows.deliveryDate'),
          dataIndex: 'proposedDate',
          render: (value) => formatDate(intl, dayjs(value)),
        },
        {
          key: 'quantity',
          width: 150,
          title: translate(intl, 'order.details.quotationRows.quantity'),
          dataIndex: 'quantity',
          render: (value, { unit }) => `${formatPortalNumber(value || 0)} ${translateUnit(intl, unit)}`,
        },
        {
          key: 'unitPrice',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.unitPrice' ,{ currency: currency }),
          render: (value, record) => (value ? `${formatPortalNumber(value, {fractionDigits: 4})}` : '-'),
          dataIndex: 'price',
        },
        {
          key: 'price',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.price', { currency: currency }),
          dataIndex: 'price',
          render: (_, record) => `${formatPortalNumber((record.linePrice ?? ((record.price ?? 0) * (record.quantity ?? 0))), { fractionDigits: 2 })}`,
        },
      ] as ColumnType<SalesOrderProduct>[],
    [intl, currency]
  );

  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Table<SalesOrderProduct> loading={isLoading} columns={columns} dataSource={salesOrderRows?.data || []} rowKey="id" pagination={false} />
    </Card>
  );
};

export default OrderQuotationRows;
