import { App, Button, Form, Input, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import React, { useCallback, useEffect } from 'react';
import { AppSettingRequest, AppSettingType } from '../../../persistence/model/AppSetting.ts';
import { useGetContractTermsQuery, useSaveContractTermMutation } from '../../../persistence/appSettingsApiSlice.ts';

type FormItemRowProps = {
  currentValue: string;
  type: 'et' | 'en';
};

const ContractTermSettings = () => {
  const intl = useIntl();
  const { message } = App.useApp();
  const [saveContractTerms] = useSaveContractTermMutation();
  const [form] = Form.useForm();
  const { data: contractTerms } = useGetContractTermsQuery();

  useEffect(() => {
    if (contractTerms) {
      form.setFieldsValue({
        en: contractTerms.data.value.urlEn,
        et: contractTerms.data.value.urlEt,
      })
    }
  }, [contractTerms]);

  const submitHandler = useCallback(
    async () => {
      const request: AppSettingRequest<AppSettingType.CONTRACT_TERM> = {
        type: AppSettingType.CONTRACT_TERM,
        value: {
          urlEt: form.getFieldValue('et'),
          urlEn: form.getFieldValue('en'),
        },
      };
      await saveContractTerms(request)
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'portalSettings.message.success'));
        })
        .catch(() => {
          message.error(translate(intl, 'portalSettings.message.error'));
        });
    }, [form, intl],
  );


  const FormItemRow = ({ currentValue, type }: FormItemRowProps) => {
    return (
      <>
        <Typography.Title level={5}>
          <FormattedMessage id={'portalSettings.contracts.type.' + type} />
        </Typography.Title>
        <span style={{ fontWeight: 600 }}>
          <FormattedMessage id={'portalSettings.current'} />
        </span>
        <a href={currentValue} target="_blank">
          {currentValue}
        </a>
        <Form.Item name={type} rules={[{ required: true }, { type: 'url' }]} style={{ padding: 0 }}>
          <Input style={{ width: 350 }} />
        </Form.Item>
      </>
    );
  };


  return (
    <Form form={form}>
      <FormItemRow currentValue={contractTerms?.data?.value.urlEt} type={'et'} />
      <FormItemRow currentValue={contractTerms?.data?.value.urlEn} type={'en'} />
      <Form.Item>
        <Button type="primary" onClick={() => submitHandler()}>
          <FormattedMessage id={'portalSettings.save'} />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContractTermSettings;