import { App, Button, Form, Switch, Typography } from 'antd';
import { useGetBannerSettingsQuery, useSaveBannerSettingsMutation } from '../../../persistence/appSettingsApiSlice.ts';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { AppSettingRequest, AppSettingType } from '../../../persistence/model/AppSetting.ts';
import { translate } from '../../../translations/TranslationUtils.ts';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

type FormItemRowProps = {
  type: 'et' | 'en';
};

const BannerSettings = () => {
  const intl = useIntl();
  const { message } = App.useApp();
  const { data: bannerSettings } = useGetBannerSettingsQuery();
  const [saveBannerSettings] = useSaveBannerSettingsMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (bannerSettings) {
      form.setFieldsValue({
        et: bannerSettings.data.value.descriptionEt,
        en: bannerSettings.data.value.descriptionEn,
        checkbox: bannerSettings.data.value.toggleBanner,
      })
    }
  }, [bannerSettings]);

  const FormItemRow = ({ type }: FormItemRowProps) => {
    return (
      <>
        <Typography.Title level={5}>
          <FormattedMessage id={'portalSettings.banner.description.' + type} />
        </Typography.Title>
        <Form.Item name={type} rules={[{ required: true }]} style={{ padding: 0 }}>
          <TextArea style={{ width: 450 }} />
        </Form.Item>
      </>
    );
  };

  const submitHandler = useCallback(
    async () => {
      const request: AppSettingRequest<AppSettingType.BANNER> = {
        type: AppSettingType.BANNER,
        value: {
          descriptionEt: form.getFieldValue("et"),
          descriptionEn: form.getFieldValue("en"),
          toggleBanner: form.getFieldValue("checkbox")
        },
      };
      await saveBannerSettings(request)
        .unwrap()
        .then(() => {
          message.success(translate(intl, 'portalSettings.message.success'));
        })
        .catch(() => {
          message.error(translate(intl, 'portalSettings.message.error'));
        });
    }, [form, intl],
  );

  return (
    <Form form={form}>
      <FormItemRow type={'et'} />
      <FormItemRow type={'en'} />
      <Typography.Title level={5}>
        <FormattedMessage id={'portalSettings.banner.description.toggleBanner'} />
      </Typography.Title>
      <Form.Item name={'checkbox'}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => submitHandler()}>
          <FormattedMessage id={'portalSettings.save'} />
        </Button>
      </Form.Item>
    </Form>
  )
}

export default BannerSettings