import { Audit } from './Common.ts';

export enum AppSettingType {
  CONTRACT_TERM = 'CONTRACT_TERM',
  BANNER = 'BANNER'
}

type ContractTermFields = {
  urlEn: string;
  urlEt: string;
}

export type BannerSettingsFields = {
  toggleBanner: boolean;
  descriptionEn: string;
  descriptionEt: string;
}

type AppSettingsRequestTypes = {
  [AppSettingType.CONTRACT_TERM]: ContractTermFields,
  [AppSettingType.BANNER]: BannerSettingsFields,
}

export type AppSettingRequest<T extends AppSettingType> = {
  type: AppSettingType;
  value: AppSettingsRequestTypes[T]
}

export type AppSettingsResponse = {
  id: number;
  type: AppSettingType;
} & Audit

export type ContractTermsResponse = {
  value: ContractTermFields
} & AppSettingsResponse

export type BannerSettingsResponse = {
  value: BannerSettingsFields
}