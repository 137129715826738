import PublisherApiPage from './publisher-api/PublisherApiPage.tsx';
import { Collapse, CollapseProps, Typography } from 'antd';
import React from 'react';
import ContractTermSettings from './portal-settings/ContractTermSettings.tsx';
import BannerSettings from './BannerSettings/BannerSettings.tsx';

const AdminPage = (): JSX.Element => {

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Banner settings',
      children: <BannerSettings />,
    },
    {
      key: '2',
      label: 'Contract term settings',
      children: <ContractTermSettings />,
    },
    {
      key: '3',
      label: 'Publisher tables',
      children: <PublisherApiPage />,
    },
  ];


  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Typography.Title level={4}>
        Admin
      </Typography.Title>
      <Collapse accordion items={items} defaultActiveKey={[1]} />
    </div>
  );
};

export default AdminPage;

