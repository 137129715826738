import { apiSlice } from './apiSlice.ts';
import { CommonApiResponse } from './model/Common.ts';
import {
  CustomerStandardProductSalesUnit,
  GetProductsRequest,
  Product,
  ProductGroupDeliveryTerm,
  ProductUnitMap,
  SaveStandardProductCustomerSalesUnitRequest,
  StandardProductGroup,
  StandardProductsWithUnits,
  UpdateProductStatusRequest,
} from './model/Product.ts';

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveCustomProducts: builder.query<CommonApiResponse<Product[]>, number>({
      query: (customerId) => ({
        url: `/api/products/active?customerId=${customerId}`,
      }),
      providesTags: ['ALL', 'CustomProducts'],
    }),
    getStandardProducts: builder.query<CommonApiResponse<StandardProductGroup[]>, number>({
      query: (customerId) => ({
        url: `/api/products/${customerId}/common-groups`,
      }),
      providesTags: ['ALL', 'StandardProducts'],
    }),
    getProducts: builder.query<CommonApiResponse<Product[]>, GetProductsRequest>({
      query: (params) => ({
        url: `/api/products`,
        params,
      }),
      providesTags: ['ALL', 'Products'],
    }),
    updateProductStatus: builder.mutation<CommonApiResponse<Product>, UpdateProductStatusRequest>({
      query: (request) => ({
        url: `/api/products/${request.productId}/status`,
        method: 'PUT',
        body: { status: request.status },
      }),
      invalidatesTags: ['Products'],
    }),
    getUnits: builder.query<CommonApiResponse<string[]>, void>({
      query: () => ({
        url: `/api/products/units`,
      }),
      providesTags: ['ALL', 'Units'],
    }),
    getAvailableStandardProductsWithUnits: builder.query<CommonApiResponse<StandardProductsWithUnits[]>, void>({
      query: () => ({
        url: `/api/standard-products/available-units`,
      }),
      providesTags: ['ALL', 'AvailableStandardProductsWithUnits'],
    }),
    getCustomerStandardProductsSalesUnits: builder.query<CommonApiResponse<CustomerStandardProductSalesUnit[]>, number>({
      query: (customerId) => ({
        url: `/api/standard-products/${customerId}/sales-units`,
      }),
      providesTags: ['ALL', 'CustomerStandardProductsSalesUnits'],
    }),
    getCustomerProductGroups: builder.query<CommonApiResponse<ProductGroupDeliveryTerm[]>, number>({
      query: (customerId) => ({
        url: `/api/products/customer/${customerId}/item-groups`,
      }),
      providesTags: ['ALL', 'CustomerProductGroupDeliveryTerms'],
    }),
    saveStandardProductCustomerSalesUnit: builder.mutation<CommonApiResponse<boolean>, SaveStandardProductCustomerSalesUnitRequest>({
      query: (request) => ({
        url: `/api/standard-products/${request.customerId}/sales-units`,
        method: 'POST',
        body: request.productAvailableSalesUnit,
      }),
      invalidatesTags: ['CustomerStandardProductsSalesUnits'],
    }),
    getCustomerProductsUnitMap: builder.query<CommonApiResponse<ProductUnitMap[]>, number>({
      query: (customerId) => ({
        url: `/api/products/units-map/${customerId}`,
      }),
      providesTags: ['ALL', 'CustomerProductsUnitMap'],
    })
  }),
});

export const {
  useGetActiveCustomProductsQuery,
  useGetStandardProductsQuery,
  useGetProductsQuery,
  useUpdateProductStatusMutation,
  useGetUnitsQuery,
  useGetAvailableStandardProductsWithUnitsQuery,
  useGetCustomerStandardProductsSalesUnitsQuery,
  useSaveStandardProductCustomerSalesUnitMutation,
  useGetCustomerProductGroupsQuery,
  useGetCustomerProductsUnitMapQuery,
} = productApiSlice;
