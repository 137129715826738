import { BannerSettingsFields } from '../../../persistence/model/AppSetting.ts';
import { Language } from '../../../persistence/model/Common.ts';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../../persistence/authSlice.ts';

type BannerProps = {
  banner: BannerSettingsFields
}

const Banner = ({banner}: BannerProps) => {
  const lang = useSelector(localeSelector)
  const bannerText = useMemo(() => {
    return lang === Language.EN ? banner.descriptionEn : banner.descriptionEt;
  }, [lang, banner])

  return (
    <div style={{ backgroundColor: '#f1c400', padding: 0, textAlign: 'center', height: 45, justifyContent: "center", lineHeight: 3}}>
      <span style={{ fontSize: 16 }}>{bannerText}</span>
    </div>
  )
}

export default Banner