import { useSelector } from 'react-redux';
import { apiSlice } from './apiSlice.ts';
import { representsCustomers } from './authSlice.ts';
import { CommonApiResponse } from './model/Common.ts';
import { Customer, CustomerData, CustomerDeliveryDaysType, CustomerManager, CustomerUser } from './model/Customer.ts';

const EMPTY_LIST: any[] = [];

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<CommonApiResponse<Customer[]>, void>({
      query: () => ({
        url: '/api/customers',
        method: 'GET',
      }),
      providesTags: ['ALL', 'Customers'],
    }),
    getRepresentativeCustomers: builder.query<CommonApiResponse<Customer[]>, void>({
      query: () => ({
        url: '/api/customers/representative',
        method: 'GET',
      }),
      providesTags: ['ALL', 'RepresentativeCustomers'],
    }),
    getCustomerData: builder.query<CommonApiResponse<CustomerData>, number>({
      query: (customerId) => ({
        url: `/api/customers/${customerId}`,
        method: 'GET',
      }),
      providesTags: ['ALL', 'CustomerData'],
    }),
    getCustomerUsers: builder.query<CommonApiResponse<CustomerUser[]>, number>({
      query: (customerId) => ({
        url: `/api/customers/${customerId}/users`,
        method: 'GET',
      }),
      providesTags: ['ALL', 'CustomerUsers'],
    }),
    getCustomerManagers: builder.query<CommonApiResponse<CustomerManager[]>, number>({
      query: (customerId) => ({
        url: `/api/customers/${customerId}/managers`,
        method: 'GET',
      }),
      providesTags: ['ALL', 'CustomerManagers'],
    }),
    getCustomerStockMonths: builder.query<CommonApiResponse<string>, number>({
      query: (customerId) => ({
        url: `/api/customers/${customerId}/stock-months`,
        method: 'GET',
      })
    }),
    getCustomerDeliveryDays: builder.query<CommonApiResponse<CustomerDeliveryDaysType>, number>({
      query: (customerId) => ({
        url: `/api/customers/${customerId}/delivery-days`,
        method: 'GET',
      })
    }),
  }),
});

export const { useGetCustomerUsersQuery, useGetCustomerDataQuery, useGetCustomerManagersQuery, useGetCustomerStockMonthsQuery, useGetCustomerDeliveryDaysQuery } = customerApiSlice;

export const useGetRepresentativeCustomers = (params?: { myCustomersOnly?: boolean }): { data: Customer[] } => {
  const customers = useSelector(representsCustomers);

  return { data: customers?.filter((c) => (params?.myCustomersOnly ? !!c.myCustomer : true)) || EMPTY_LIST };
};
