import dayjs from 'dayjs';
import { Button, DatePicker, Form, InputNumber, Modal, Space } from 'antd';
import { InventoryItem } from '../../../persistence/model/InventoryItem';
import { translate } from '../../../translations/TranslationUtils.ts';
import { useIntl } from 'react-intl';
import { useEffect, useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { formatPortalNumber } from '../../../util/NumberUtil';
import { CallOffCart, getItemBalance, getMinDeliveryDate } from './CallOffCartLogic';
import { formatDate } from '../../../util/DateUtil';
import { DatePickerValueCalculator } from '../../order/edit/OrderEditItemsFormHelper.ts';
import { CustomerDeliveryDaysType } from '../../../persistence/model/Customer.ts';

type CallOffCartAddItemModalProps = {
  inventoryItem: InventoryItem;
  callOffCart: CallOffCart;
  onCancel: () => void;
  onSubmit: (data: any) => void;
  customerDeliveryDays: CustomerDeliveryDaysType;
};

const CallOffCartAddItemModal = ({ inventoryItem, callOffCart, onCancel, onSubmit, customerDeliveryDays }: CallOffCartAddItemModalProps): JSX.Element => {
  const intl = useIntl();
  const [form] = useForm();
  const itemsLeft = getItemBalance(inventoryItem);

  useEffect(() => {
    if (inventoryItem) {
      const cartItemId = inventoryItem.inventTransId;
      const existingCartItem = callOffCart.items[cartItemId];

      if (existingCartItem) {
        form.setFieldsValue(existingCartItem);
      } else {
        const minDeliveryDate = getMinDeliveryDate(inventoryItem, 0);
        const allCartItems = Object.values(callOffCart.items);
        const lastItem = allCartItems.length ? allCartItems[allCartItems.length - 1] : undefined;

        let defaultDate = lastItem?.date;
        if (defaultDate?.isBefore(minDeliveryDate)) {
          defaultDate = minDeliveryDate;
        }

        form.setFieldsValue({
          id: cartItemId,
          item: inventoryItem,
          amount:
            Math.max(0, Math.min(itemsLeft, inventoryItem.quantityInWarehouse)) !== 0
              ? Math.max(0, Math.min(itemsLeft, inventoryItem.quantityInWarehouse))
              : null,
          date: defaultDate,
        });
      }
    }
  }, [inventoryItem, callOffCart, form, itemsLeft]);

  const minDate = (() => {
    const currentHour = dayjs().hour();
    const baseDays = inventoryItem?.deliveryDays ?? 0;

    const adjustedDays = baseDays === 0
      ? (currentHour < 11 ? 0 : 1)
      : baseDays;

    return dayjs().add(adjustedDays, 'days').startOf('day');
  })();
  const disabledDate = useMemo(() => {
    if (customerDeliveryDays) {
      return DatePickerValueCalculator(customerDeliveryDays, inventoryItem?.deliveryDays ?? 0, minDate)
    }
  }, [customerDeliveryDays, inventoryItem]);

  return (
    <Modal open={!!inventoryItem} onCancel={onCancel} title={translate(intl, 'callOffCart.addItemModal.title')} footer={null} width={630}>
      <Form layout="vertical" form={form} onFinish={onSubmit} style={{ paddingTop: 10 }}>
        <Form.Item hidden name="id" />
        <Form.Item label={translate(intl, 'callOffCart.addItemModal.product')} name="item" className="form-text-label">
          <div>{inventoryItem?.productName}</div>
          <div style={{ opacity: 0.6 }}>{inventoryItem?.externalItemId}</div>
        </Form.Item>
        <Form.Item
          label={translate(intl, 'callOffCart.addItemModal.amount')}
          help={translate(intl, 'callOffCart.addItemModal.amountHelper', {
            itemsLeft: `${formatPortalNumber(itemsLeft)} ${inventoryItem?.unit}`,
            itemsInStock: `${formatPortalNumber(inventoryItem?.quantityInWarehouse)} ${inventoryItem?.unit}`,
          })}
          name="amount"
          rules={[{ required: true }, { type: 'number', min: 0 }, { type: 'number', max: itemsLeft }]}
        >
          <InputNumber controls={false} onChange={() => !!form.getFieldValue('date') && form.validateFields(['date'])} />
        </Form.Item>
        <Form.Item
          label={translate(intl, 'callOffCart.addItemModal.deliveryDate')}
          name="date"
          rules={[
            { required: true, type: 'object' },
            {
              validator: (_, value) => {
                const requiredAmount = form.getFieldValue('amount');
                const minDeliveryDate = getMinDeliveryDate(inventoryItem, requiredAmount);

                if (value && value.isBefore(minDeliveryDate, 'day')) {
                  return Promise.reject(translate(intl, 'callOffCart.addItemModal.deliveryDateError', { minDeliveryDate: formatDate(intl, minDeliveryDate) }));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DatePicker
            format={translate(intl, 'config.dateFormat')}
            allowClear={false}
            disabledDate={disabledDate}
            minDate={minDate}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
          <Space>
            <Button onClick={onCancel} className="uppercase">
              {translate(intl, 'common.cancel')}
            </Button>
            <Button type="primary" htmlType="submit" className="uppercase">
              {translate(intl, 'callOffCart.addItemModal.addToCart')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CallOffCartAddItemModal;
