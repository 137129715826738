import { ComplaintType } from '../components/dashboard/DashboardComplaintChart.tsx';
import { CreditChartCategory } from '../components/dashboard/DashboardCreditChart.tsx';
import { DeliveryType } from '../components/dashboard/DashboardDeliveryChart.tsx';
import { UserType } from '../persistence/model/Auth.ts';
import { AxSalesStatus } from '../persistence/model/CallOff.ts';
import { ComplaintDecisionType, ComplaintStatus } from '../persistence/model/Complaint.ts';
import { OrderCommentType, OrderStatus, OrderType } from '../persistence/model/Order.ts';
import { ProductActiveFilter } from '../persistence/model/Product.ts';
import { UserTypeFilter } from '../persistence/model/User.ts';

const translations = {
  portalSettings: {
    title: 'Portaali seaded',
    current: 'Praegune: ',
    save: "Salvesta",
    contracts: {
      contractEn: 'EN leping url',
      contractEt: 'ET leping url',
      type: {
        et: 'Eestikeelne leping',
        en: 'Inglisekeelne leping',
      },
    },
    banner: {
      description: {
        et: 'Eestikeelne kirjeldus',
        en: 'Ingliskeelne kirjeldus',
        toggleBanner: 'Banneri nähtavus',
      }
    },
    message: {
      success: "Salvestamine õnnetus",
      error: "Salvestamine ebaõnnestus",
    },
  },
  menu: {
    title: 'Menüü',
    dashboard: 'Töölaud',
    orders: 'Tellimused',
    delivery: 'Tarned',
    callOffs: 'Väljakutsed',
    inventory: 'Laoseis',
    products: 'Tooted',
    complaints: 'Kaebused',
    users: 'Kasutajad',
    contact: {
      title: 'Võta ühendust',
      sendMail: 'Saada e-kiri',
    },
  },
  login: {
    title: 'Logi sisse',
    userName: 'Kasutajanimi',
    password: 'Parool',
    forgotPassword: 'Unustasid parooli',
    loginButton: 'Logi sisse',
    loginError: 'Vale kasutaja nimi või parool',
    usernameRequired: 'Kasutajanimi on kohustuslik',
    passwordRequired: 'Parool on kohustuslik',
  },
  resetPasswordRequest: {
    title: 'Parooli lähtestamine',
    username: 'Sisesta kasutajanimi või email',
    sendRequest: 'Lähtesta',
    success: 'Parooli lähtestamise email on saadetud',
    error: 'Parooli lähtestamine ebaõnnestus',
  },
  resetPassword: {
    title: 'Parooli muutmine',
    username: 'Kasutajanimi',
    password: 'Parool',
    confirmPassword: 'Parool uuesti',
    success: 'Parool edukalt muudetud',
    error: 'Parooli muutmine ebaõnnestus',
    passwordRequired: 'Parool on kohustuslik',
    passwordMinLength: 'Parool peab olema vähemalt 8 tähemärki',
    passwordMismatch: 'Paroolid ei ühti',
    resetButton: 'Muuda',
  },
  completeInvitation: {
    title: 'Parooli määramine',
    username: 'Kasutajanimi',
    password: 'Parool',
    confirmPassword: 'Parool uuesti',
    success: 'Parool edukalt määratud',
    error: 'Parooli määramine ebaõnnestus',
    passwordMismatch: 'Paroolid ei ühti',
    send: 'Salvesta',
    hashInvalid: 'Kutse link on aegunud või vigane',
  },
  common: {
    add: 'Lisa',
    searchPlaceholder: 'Otsi',
    continue: 'Jätka',
    customer: 'Klient',
    selectCustomer: 'Vali klient',
    pageNotFound: 'Lehekülge ei leitud',
    homeButton: 'Avalehele',
    yes: 'Jah',
    no: 'Ei',
    create: 'Loo',
    confirm: 'Kinnita',
    cancel: 'Katkesta',
    close: 'Sulge',
    back: 'Tagasi',
    delete: 'Kustuta',
    allCustomers: 'Kõik kliendid',
    showAllCustomers: 'Kuva kõik kliendid',
    noInternetConnection: 'Puudub internetiühendus või ühendus serveriga',
    duplicate: 'Dubleeri toode',
    save: 'Salvesta',
    shortMonth: {
      1: 'jaan',
      2: 'veeb',
      3: 'märts',
      4: 'apr',
      5: 'mai',
      6: 'juuni',
      7: 'juuli',
      8: 'aug',
      9: 'sept',
      10: 'okt',
      11: 'nov',
      12: 'dets',
    },
  },
  config: {
    dateFormat: 'DD/MM/YYYY',
    longDateFormat: 'DD/MM/YYYY HH:mm:ss',
    longDateFormatWithoutSeconds: 'DD/MM/YYYY HH:mm',
  },
  dashboard: {
    newPortalNotification: 'Tere tulemast Estiko uuenenud kliendiportaali! Värskenduste käigus on meie portaal saanud uue ilme ning mitmeid lisafunktsionaalsusi, et pakkuda teile veelgi mugavamat kasutajakogemust.{br}{br}' +
      'Küsimuste või murede korral andke palun märku - oleme teie jaoks olemas.{br}{br}' +
      'Head kasutamist!',
    welcomeMessage: 'Tere, {name}!',
    pendingOrders: 'Esitamist või kinnitamist ootab',
    pendingOrdersValue: '{num} tellimust',
    pendingOrdersValueSingle: '{num} tellimus',
    productsInInventory: 'Laos tooteid',
    productsInInventoryValue: '{num} toodet',
    productsInInventoryValueSingle: '{num} toode',
    greenAlternativesTitle: 'Hinnake oma pakendite kestlikkust',
    greenAlternativesDescription:
      'Teie toodete jätkusuutlikkus on otseselt seotud pakendi loomisel tehtud valikutega. Meie nutikas tööriist aitab teil leida viise oma pakendite ringlussevõetavuse tõstmiseks ja hinnata nende valikute mõju keskkonnale. Soovi korral saate kestlikuma alternatiivi ettepaneku edastada mugavalt oma müügijuhile.',
    greenAlternativesButton: 'Tutvu lähemalt',
    orderChart: {
      deliveryType: {
        [DeliveryType.ON_TIME]: 'Tähtaegselt tarnitud',
        [DeliveryType.EARLY]: 'Enne tähtaega tarnitud tellimused',
        [DeliveryType.LATE]: 'Hilinenud tellimused',
      },
    },
    creditChart: {
      category: {
        [CreditChartCategory.CREDIT_LIMIT]: 'Krediidilimiit',
        [CreditChartCategory.CREDIT_BALANCE]: 'Kasutatud limiit',
        [CreditChartCategory.CREDIT_PREDICTION]: 'Limiidi prognoos',
      },
      paymentCriteria: 'Maksetingimus',
      debt: 'Võlgnevus',
      averageDelay: 'Keskmiselt hilinetud tasumisega',
      averageDelayValue: '{num} päeva',
      averageDelayValueSingle: '{num} päev',
    },
    complaintChart: {
      noComplaints: 'Viimase 12 kuu jooksul kaebused puuduvad',
      type: {
        [ComplaintType.SUBMITTED]: 'Esitatud kaebused',
      },
    },
  },
  order: {
    add: 'Lisa',
    title: 'Tellimus',
    purchaseOrders: 'Ostutellimused',
    search: 'Ostu või müügitellimuse number',
    myOrders: 'Ainult minu tellimused',
    send: 'Esita',
    details: {
      customer: 'Klient',
      deliveryAddress: 'Tarne aadress',
      lastModifiedAt: 'Viimati muudetud',
      paymentTerm: 'Makseaeg',
      creditLimit: 'Krediidilimiit',
      deliveryTerm: 'Tarnetingimus',
      orderedRowsTitle: 'Esitatud ostutellimus',
      confirmationTitle: 'Tellimuse kinnitus',
      commentsTitle: 'Märkused',
      quotationRows: {
        product: 'Toode',
        deliveryDate: 'Tarneaeg',
        quantity: 'Kogus',
        delivered: 'Tarnitud',
        unitPrice: 'Ühiku hind ({currency})',
        price: 'Hind ({currency})',
        offeredDeliveryDate: 'Pakutud tarneaeg',
        calledOffQuantity: 'Välja kutsutud',
      },
    },
    comment: {
      version: 'Versioon',
      comment: 'Märkus',
      createdAt: 'Loodud',
      createdBy: 'Looja',
      typeTitle: 'Tüüp',
      type: {
        [OrderCommentType.CREATION_COMMENT]: 'Uus tellimus',
        [OrderCommentType.DECLINE_COMMENT]: 'Tagasi lükatud',
        [OrderCommentType.SYSTEM_COMMENT]: 'Süsteemne',
      },
    },
    form: {
      title: 'Tellimuse koostamine',
      selectOrderType: 'Vali tellimuse liik',
      orderTypeChangeTooltip: 'Teist liiki toodete valimiseks palun lisa uus tellimus.',
      selectProduct: 'Vali toode',
      selectStandardProduct: 'Vali standardtoode',
      selectProductPlaceholder: 'Sisesta toote nimi, kood või artikli nr',
      standardProductName: '{quantity} {unit} laos',
      itemsCount: '{count} toodet',
      orderItems: 'Tellitavad tooted',
      noItemsPlaceholder: 'Tellimusele ei ole veel ühtegi toodet lisatud. Lisamiseks otsi ja vali toode',
      itemsTable: {
        item: 'Toode',
        quantity: 'Kogus',
        unit: 'Ühik',
        deliveryDate: 'Tarneaeg',
        available: 'Laos: {count} {unit}',
      },
      additionalInfo: 'Täiendav info',
      deliveryAddress: 'Tarneaadress',
      deliveryAddressPlaceholder: 'Vali aadress',
      orderNumber: 'Ostutellimuse nr',
      orderNumberPlaceholder: 'Sisesta enda ostutellimuse nr',
      comment: 'Kommentaar',
      commentPlaceholder: 'Sisesta kommentaar',
      orderType: {
        [OrderType.DEFAULT]: 'Minu tooted',
        [OrderType.STANDARD]: 'Estiko standardtooted',
      },
    },
    action: {
      confirm: {
        button: 'Kinnita',
        title: 'Tellimuse kinnitamine',
        term1: 'Nõustun',
        term2: 'lepingutingimustega',
        text: 'Kinnita nupule vajutamisega nõustud tellimuse kinnitusega ja tellimus läheb tootmisesse. Kui soovid enne kinnitamist veel detaile kontrollida, vajuta "Sulge".',
        textWithTerms:
          'Kui nõustud lepingutingimustega ja vajutad “Kinnita” nuppu, läheb tellimus tootmisesse.  Kui soovid enne kinnitamist veel detaile kontollida, vajuta "Sulge".',
        termsValidationError: 'Palun nõustu lepingutingimustega',
      },
      decline: {
        button: 'Lükka tagasi',
        title: 'Tellimuse kinnituse tagasi lükkamine',
        label: 'Miks soovid tagasi lükata?',
        placeholder: 'Palun sisesta selgitus',
      },
      delete: {
        title: 'Tellimuse kustutamine',
        confirmation: 'Oled kindel et soovid tellimuse kustutada?',
      },
    },
    table: {
      customerName: 'Klient',
      purchaseOrderNum: 'Ostutellimuse nr',
      salesOrderNum: 'Müügitellimuse nr',
      address: 'Aadress',
      created: 'Loodud',
      status: 'Staatus',
    },
    status: {
      [OrderStatus.DRAFT]: 'Mustand',
      [OrderStatus.ORDERED]: 'Tellimus edastatud',
      [OrderStatus.UNCONFIRMED]: 'Kinnitamata',
      [OrderStatus.REVIEW]: 'Ülevaatamiseks',
      [OrderStatus.CONFIRMED]: 'Kinnitatud',
      [OrderStatus.DELIVERED]: 'Tarnitud',
    },
    message: {
      creationFailed: 'Tellimuse loomine ebaõnnestus',
      orderSuccess: 'Tellimus edastatud',
      orderFailed: 'Tellimuse edastamine ebaõnnestus',
      confirmSuccess: 'Täname! Teie tellimus on tootmisesse edastatud.',
      confirmFailed: 'Pakkumise kinnitamine ebaõnnestus',
      declineSuccess: 'Pakkumine tagasi lükatud',
      declineFailed: 'Pakkumise tagasilükkamine ebaõnnestus',
      deleteSuccess: 'Tellimus kustutatud',
      deleteFailed: 'Tellimuse kustutamine ebaõnnestus',
      validationFailed: 'Tellimuse vormi validatsioon ebaõnnestus',
    },
  },
  callOff: {
    title: 'Väljakutsed',
    clientSelectPlaceholder: 'Klient',
    searchPlaceholder: 'Väljakutse ID või nr',
    table: {
      callOffNr: 'ID',
      callOffOrderNumb: 'Väljakutse nr',
      createdAt: 'Loodud',
      comment: 'Märkused',
      calculatedStatus: 'Staatus',
    },
    status: {
      [AxSalesStatus.BACKORDER]: 'Avatud',
      [AxSalesStatus.DELIVERED]: 'Tarnitud',
      [AxSalesStatus.INVOICED]: 'Arveldatud',
      [AxSalesStatus.CANCELED]: 'Tühistatud',
    },
    details: {
      callOff: 'Väljakutse',
      customerName: 'Klient',
      createdAt: 'Loodud',
      comment: 'Märkused',
      table: {
        title: 'Väljakutsutud tooted',
        product: 'Toode',
        order: 'Tellimus',
        quantity: 'Väljastatav kogus',
        departureDate: 'Estiko laost väljastada',
        status: 'Staatus',
      },
    },
  },
  callOffCart: {
    bar: {
      productsInCallOff: 'Väljakutses tooteid: {productsInCallOff}',
      goToSummary: 'Väljakutse koondvaade',
    },
    addItemModal: {
      title: 'Toote väljakutse',
      product: 'Toode',
      amount: 'Väljastatav kogus',
      amountHelper: 'tellimuse jääk: {itemsLeft}; laos: {itemsInStock}',
      deliveryDate: 'Estiko laost väljastamise kuupäev',
      deliveryDateError: 'Valitud kogust ei saa tarnida varem kui {minDeliveryDate}',
      addToCart: 'Lisa väljakutsesse',
    },
    summary: {
      table: {
        title: 'Väljakutsutavad tooted',
        product: 'Toode',
        order: 'Tellimus',
        remainingQuantity: 'Tellimuse jääk',
        inWarehouse: 'Laos',
        amount: 'Väljastatav kogus',
        deliveryDate: 'Estiko laost väljastada',
      },
      additionalInfo: 'Täiendav info',
      callOffNr: 'Väljakutse nr',
      callOffNrPlaceholder: 'Sisesta enda väljakutse nr',
      comments: 'Märkused',
      commentsPlaceholder: 'Sisesta lisainfo',
      backToInventory: 'Tagasi laoseisu',
      submit: 'Esita',
      submitSuccessful: 'Väljakutse esitatud',
      submitFailed: 'Väljakutse esitamine ebaõnnestus: {message}',
    },
  },
  inventory: {
    title: 'Laoseis',
    productSearchPlaceholder: 'Toode või artikkel',
    orderSearchPlaceholder: 'Ostu- või müügitellimuse nr',
    showInStockOnly: 'Laos olemas',
    download: 'Laadi alla',
    downloadFailed: 'Allalaadimine ebaõnnestus: {message}',
    table: {
      customer: 'Klient',
      productName: 'Toode',
      salesOrderId: 'Tellimus',
      deliveryDate: 'Tarne kp',
      orderedQuantity: 'Tellitud',
      deliveredQuantity: 'Tarnitud',
      quantityInWarehouse: 'Laos',
      inCallOff: 'Väljakutses',
      expired: 'Hoiustamisaeg läbi',
      expandable: {
        createdAtDate: 'Väljakutse loodud',
        orderedQuantity: 'Väljakutsutud kogus',
        deliveryDate: 'Laost väljastamise kp',
        status: 'Staatus',
      },
    },
    tab: {
      openOrders: 'Avatud tellimused',
      allOrders: 'Kõik tellimused',
    },
  },
  unit: {
    kg: 'kg',
    tk: 'tk',
    rull: 'rulli',
    jm: 'jm',
    m: 'jm',
    pc: 'tk',
    reel: 'rulli',
    karp: 'karp'
  },
  product: {
    title: 'Tooted',
    searchPlaceholder: 'Toode või artikkel',
    clientSelectPlaceholder: 'Klient',
    activeFilter: {
      [ProductActiveFilter.ALL]: 'Kõik',
      [ProductActiveFilter.ACTIVE]: 'Aktiivsed',
      [ProductActiveFilter.INACTIVE]: 'Inaktiivsed',
    },
    table: {
      product: 'Toode',
      alternative: 'Alternatiiv',
      active: 'Aktiivne',
      deactivate: 'Deaktiveeri toode',
      pending: 'Toodet deaktiveeritakse. See võib pisut aega võtta.',
      contactAdmin: 'Toode pole aktiivne. Kui soovid toodet aktiveerida, võta palun ühendust Estiko spetsialistiga.',
    },
  },
  complaint: {
    add: 'Lisa',
    allCustomers: 'Kõik kliendid',
    searchPlaceholder: 'Kaebuse, ostutellimuse või arve number',
    table: {
      customerName: 'Klient',
      id: 'Kaebuse nr',
      purchaseOrderNumber: 'Ostutellimuse nr',
      invoiceNumber: 'Arve nr',
      createdAt: 'Loodud',
      productCode: 'Toote kood',
      status: 'Staatus',
    },
    creation: {
      title: 'Vali klient',
      customer: 'Klient',
      customerPlaceholder: 'Vali klient',
      recipient: 'Vastuse saaja',
      recipientPlaceholder: 'Vali vastuse saaja',
      noRecipient: 'Kliendile teavitusi ei saadeta',
      nextButton: 'Edasi',
    },
    status: {
      [ComplaintStatus.DRAFT]: 'Mustand',
      [ComplaintStatus.UNDER_REVIEW]: 'Lahendamisel',
      [ComplaintStatus.SOLVED]: 'Lahendatud',
      [ComplaintStatus.REJECTED]: 'Tagasi lükatud',
      [ComplaintStatus.CANCELLED]: 'Tühistatud',
    },
    decisionType: {
      [ComplaintDecisionType.RESOLVE]: 'Kaebus aktsepteeritud',
      [ComplaintDecisionType.DECLINE]: 'Lükata kaebus tagasi',
      [ComplaintDecisionType.CANCEL]: 'Kaebus tühistatud',
    },
    details: {
      title: `Kaebus: {id}`,
      customer: 'Klient',
      createdAt: 'Loodud',
      modifiedAt: 'Muudetud',
      packageNumber: 'Tootmistellimuse nr',
      purchaseOrderNumber: 'Müügitellimuse nr',
      itemCode: 'Estiko tootekood',
      quantity: 'Reklameeritav kogus',
      productionNumber: 'Reklameeritavad pakkeüksus(ed)',
      complaintDetails: {
        title: 'Kaebuse detailid',
        content: 'Kaebuse sisu',
        claim: 'Nõue',
        attachments: 'Manused',
      },
      response: {
        title: 'Estiko vastus',
        cancelledTitle: 'Tühistamise põhjus',
        decision: 'Otsus',
        description: 'Põhjendus',
        attachments: 'Manus',
      },
      resolve: {
        decisionType: {
          [ComplaintDecisionType.RESOLVE]: 'Aktsepteeri kaebus',
          [ComplaintDecisionType.DECLINE]: 'Lükka kaebus tagasi',
        },
        title: 'Kaebuse vastus',
        decision: 'Otsus',
        decisionPlaceholder: 'Palun sisesta otsus ja põhjendus',
        attachment: 'Manus',
        attachmentPlaceholder: 'Vali või lohista fail siia',
        confirmButton: 'Kinnita',
      },
    },
    edit: {
      title: 'Kaebuse koostamine',
      productionNumber: 'Tootmistellimuse nr (1)',
      itemCode: 'Estiko tootekood (2)',
      unitNumber: 'Reklameeritavad üksused (3)',
      unitNoPlaceholder: 'Reklameeritavad üksused',
      productionNumberPlaceholder: 'Tootmistellimuse nr',
      invoiceNumber: 'Arve nr',
      orderNumber: 'Müügitellimuse nr',
      quantity: 'Reklameeritav kogus',
      quantityPlaceholder: 'Sisesta kogus',
      unit: 'Ühik',
      content: 'Kaebuse sisu',
      contentPlaceholder: 'Kirjelda lähemalt kaebuse sisu',
      claim: 'Ettepanekud',
      claimPlaceholder: 'Lisa oma ettepanekud kaebuse lahendamiseks',
      attachments: 'Manused',
      details: 'Kaebuse andmed',
      dropAttachments: 'Vali või lohista fail(id) siia',
      noAttachments: 'Lisada tuleb vähemalt üks manus',
      attachmentsError: 'Manuse üleslaadimine on ebaõnnestunud, palun eemalda manus ja proovi uuesti',
      fileTooLarge: 'Faili maht ületab lubatud piiri (maksimaalselt {size} MB)',
      invalidFileType: 'Vigane faili tüüp. Lubatud faili tüübid on: {types}',
      imageDescription: '1. Üksuse nr; 2. Tootekood; 3. Tellimuse nr',
      save: 'Salvesta',
      send: 'Esita',
    },
    message: {
      saveSuccess: 'Kaebus salvestatud',
      saveFailed: 'Kaebuse salvestamine ebaõnnestus',
      submitSuccess: 'Kaebus esitatud',
      submitFailed: 'Kaebuse esitamine ebaõnnestus',
      deleteSuccess: 'Kaebus edukalt kustutatud',
      deleteFailed: 'Kaebuse kustutamine ebaõnnestus',
      cancelSuccess: 'Kaebus edukalt tühistatud',
      cancelFailed: 'Kaebuse tühistamine ebaõnnestus',
      resolveSuccess: 'Kaebus aktsepteeritud',
      resolveFailed: 'Kaebuse aktsepteerimine ebaõnnestus',
      rejectSuccess: 'Kaebus tagasi lükatud',
      rejectFailed: 'Kaebuse tagasilükkamine ebaõnnestus',
      productionSearch: {
        success: 'Tootmistellimus leitud',
        failed: 'Tootmistellimust ei leitud',
      },
      validationFailed: 'Vormi validatsioon ebaõnnestus',
    },
    action: {
      resolveButton: 'Lisa vastus',
      delete: {
        button: 'Kustuta',
        title: 'Kaebuse kustutamine',
        confirmation: 'Oled kindel et soovid kaebuse kustutada?',
      },
      cancel: {
        button: 'Tühista',
        title: 'Kaebuse tühistamine',
        confirmation: 'Oled kindel et soovid kaebuse tühistada?',
      },
    },
  },
  user: {
    title: 'Kasutajad',
    viewClient: 'Vaata klienti',
    searchPlaceholder: 'E-mail või nimi',
    typeFilter: {
      [UserTypeFilter.ALL]: 'Kõik õigused',
      [UserTypeFilter.CUSTOMER]: 'Klient',
      [UserTypeFilter.ESTIKO]: 'Müügijuht',
    },
    type: {
      [UserType.CUSTOMER]: 'Klient',
      [UserType.ESTIKO]: 'Müügijuht',
      [UserType.ADMIN]: 'Admin',
    },
    updateFromAx: 'Uuenda kasutajaid AX-ist',
    lastPullTime: 'Viimati uuendatud {time}',
    pullSuccess: 'Kasutajate info uuendatud',
    pullFailed: 'Viga kasutajate info pärimisel',
    table: {
      name: 'Nimi',
      username: 'Kasutajanimi',
      email: 'E-mail',
      numberOfCustomers: 'Kliente',
      permissions: 'Õigused',
      active: 'Aktiivne',
      isActive: 'Jah',
      notActive: 'Ei',
    },
    details: {
      userId: 'Kasutaja ID',
      username: 'Kasutajanimi',
      adUsername: 'AD kasutajanimi',
      email: 'E-mail',
      assistantEmail: 'Assistendi e-mail',
      lang: 'Keel',
      type: 'Tüüp',
      active: 'Aktiivne',
      activatedAt: 'Aktiveeritud',
      admin: 'Admin',
      designer: 'Disainer',
      loginAsCustomer: 'Logi sisse kui klient',
      isNotificationsEnabled: 'Saada teateid:',
      relatedCustomers: 'Seotud kliendid',
      save: 'Salvesta',
      isActive: 'Jah',
      notActive: 'Ei',
      deactivate: 'Deaktiveeri',
      sendInvitation: 'Saada kutse',
      isAdmin: 'Jah',
      notAdmin: 'Ei',
      isDesigner: 'Jah',
      notDesigner: 'Ei',
      avatar: 'Avatar',
      uploadAvatar: 'Laadi üles',
      message: {
        uploadAvatarSucceeded: 'Avatar edukalt üles laetud',
        uploadAvatarFailed: 'Avatari üles laadimine ebaõnnestus',
        enableNotificationsSucceeded: 'Teated sisse lülitatud',
        enableNotificationsFailed: 'Teadete sisselülitamine ebaõnnestus',
        disableNotificationsSucceeded: 'Teated välja lülitatud',
        disableNotificationsFailed: 'Teadete väljalülitamine ebaõnnestus',
        sendInvitationSucceeded: 'Kutse saadetud',
        sendInvitationFailed: 'Kutse saatmine ebaõnnestus',
        activateUserSucceeded: 'Kasutaja aktiveeritud',
        activateUserFailed: 'Kasutaja aktiveerimine ebaõnnestus',
        deactivateUserSucceeded: 'Kasutaja deaktiveeritud',
        deactivateUserFailed: 'Kasutaja deaktiveerimine ebaõnnestus',
        updateEmailsSucceeded: 'E-mailid uuendatud',
        updateEmailsFailed: 'E-mailide uuendamine ebaõnnestus',
        enableAdminSucceeded: 'Kasutaja määratud administraatoriks',
        enableAdminFailed: 'Kasutaja määramine administraatoriks ebaõnnestus',
        disableAdminSucceeded: 'Kasutajalt administraatori õigused võetud',
        disableAdminFailed: 'Kasutajalt administraatori õiguste võtmine ebaõnnestus',
        adUsernameUpdated: 'AD kasutajanimi uuendatud',
        adUsernameUpdateFailed: 'See AD kasutajanimi on juba kasutusel',
      },
    },
  },
  customer: {
    customerId: 'Kliendi id:',
    accountNumber: 'Kliendi kood:',
    language: 'Keel:',
    country: 'Riik:',
    active: 'Aktiivne:',
    yearContract: 'Aastane leping:',
    lastModified: 'Viimati muudetud:',
    type: 'Kasutaja roll',
    representativeUsers: 'Esindajad',
    managerUsers: 'Haldurid',
    addresses: 'Aadressid',
    products: 'Tooted',
    productList: {
      code: 'Tootekood',
      name: 'Nimi',
      externalItemId: 'Väline kaubakood',
      defaultUnit: 'Vaikeühik',
      salesUnit: 'Müügiühik',
      coefficient: 'Kordaja',
      unit: 'Ühik',
    },
    message: {
      unitSaveSuccess: 'Ühik salvestatud',
      unitSaveFailed: 'Ühiku salvestamine ebaõnnestus',
    },
    standardProducts: 'Standardtooted',
    fileImportSettings: 'Faili import seaded',
    address: {
      address: 'Aadress',
      type: 'Tüüp',
      uses: 'Kasutusi',
      default: 'Vaikimisi',
      alternative: 'Alternatiiv',
      uerInput: 'Kasutaja poolt sisestatud',
    },
    notifications: {
      customerNotFound: 'Klienti ei leitud',
    },
  },
  productAlternatives: {
    button: 'Alternatiivid',
    title: 'Rohelised alternatiivid',
    description: 'Teie toodete jätkusuutlikkus on otseselt seotud pakendi loomisel tehtud valikutega. Meie nutikas tööriist aitab teil leida viise oma pakendite ringlussevõetavuse tõstmiseks ja hinnata nende valikute mõju keskkonnale. Soovi korral saate kestlikuma alternatiivi ettepaneku edastada mugavalt oma müügijuhile.',
    productGroupsTitle: 'Vali tootegrupp',
    standardProduct: 'Baastoode: ',
    alternativeProduct: 'Roheline alternatiiv: ',
    additionalOptions: 'Täiendavad rohelised valikud',
    noAlternativeGroups: 'Tubli töö! Teie tooted on juba keskkonnasõbralikud. Lisaküsimuste korral võtke julgelt ühendust oma müügijuhiga.',
    iscc: {
      title: 'ISCC',
      selectPlaceholder: 'Vali osakaal',
      description:
        'Estiko omandatud ISCC sertifikaat võimaldab tarnida sertifitseeritud taastuvast või ümbertöödeldud toorainest toodetud pakendeid, aidates saavutada teie jätkusuutlikkuse eesmärke',
    },
    thinnerPackage: {
      title: 'Õhem pakend',
      select: 'Vali õhem pakend',
      selected: 'Valitud',
      description:
        'Kõige lihtsam samm kestlikuma pakendamise poole on materjalikasutuse vähendamine läbi õhemaks mineku. Seda saab teha nii lihtsamate kilede kui laminaatide puhul.',
    },
    sustainablePrint: {
      title: 'Jätkusuutlik trükk',
      select: 'Vali jätkusuutlik trükk',
      selected: 'Valitud',
      description: 'Vastutustundlik trükk laiendatud värvispektri (Extended Colour Gamut) abil pakub suuremat värvitäpsust ja erksust jätkusuutlikul moel.',
    },
    co2Offset: {
      title: 'CO\u2082 offset',
      select: 'Vali CO\u2082 offset',
      selected: 'Valitud',
      description: 'Ülejäänud osa neutraliseeritakse CO\u2082-krediidiga, võimaldades teil oma pakendite süsinikuneutraalsust tarbijatele kommunikeerida.',
      tooltip: 'Valik aktiveerub juhul, kui ISCC materjalide osakaal tootes on määratud maksimaalseks.\n',
    },
    confirmationModal: {
      text: 'Kas sa oled kindel, et tahad tootegruppi vahetada?',
      buttonOk: 'Jah',
    },
    result: {
      title: 'Teie rohelise valiku mõju',
      sendRequest: 'Küsi pakkumist',
      sendSuccess: 'Täname! Rohelise alternatiivi päring on edastatud Estiko spetsialistile. Võtame Sinuga lähiajal ühendust.',
      sendFailed: 'Viga rohelise alternatiivi päringu edastamisel',
      card: {
        co2: {
          neutral: {
            title: 'süsinikuneutraalne',
            description: 'Teie pakend on täielikult süsinikuneutraalne',
          },
          partial: {
            title: 'väiksem süsiniku jalajälg',
            description: 'Süsiniku jalajälje vähenemine saavutatud läbi:',
            downgauging: 'materjali õhemaks mineku',
            isccCertifiedMaterials: 'ISCC sertifitseeritud materjalide kasutamise',
            responsiblePrinting: 'jätkusuutlikuma trükilahenduse',
            co2Offset: 'CO\u2082 krediidi kasutamise',
          },
        },
        recyclablePackage: {
          title: 'ümbertöödeldav pakend',
          description: 'Teie pakend toetab ringmajandust, olles täielikult ümbertöödeldav selleks ettenähtud voos.',
        },
        recycledMaterial: {
          title: 'materjalist on ümbertöödeldud või taastuvatest ressurssidest',
          description: 'Teie pakend sisaldab lõpptarbija poolt taaskäitlusesse suunatud materjale.',
        },
      },
      comparison: {
        title: 'Toote süsiniku jalajälje vähenemine on võrreldav sellega, kui',
        condition: 'Eeldusel, et materjali toodetakse 1 tonn',
        disclaimer: 'Loodud tööriist ja arvutused on indikatiivsed ning ei pruugi peegeldada teie toodetele ja pakkeliinidele sobivaimat alternatiivi. Põhjalikuma ülevaate saamiseks palume edastada oma valitud kestliku alternatiivi päring oma müügijuhile, vajutades allolevat nuppu.',
        hours: '{amount} tundi',
        kgs: '{amount} kg',
        led: 'vähem põleb LED pirn',
        co2: 'vähem CO\u2082 paistatakse õhku',
      },
      notes: {
        title: 'Märkmed',
        description: 'Lisa märkmed või küsimused müügijuhile',
        send: "Saada"
      },
    },
  },
};

export default translations;
