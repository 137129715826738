import { ProductTreeSelectGroupOption, StandardProduct } from '../../persistence/model/Product.ts';
import { useIntl } from 'react-intl';
import { DetailedOrder, OrderType } from '../../persistence/model/Order.ts';
import { translate, translateUnit } from '../../translations/TranslationUtils.ts';
import { formatPortalNumber } from '../../util/NumberUtil.ts';
import { useGetActiveCustomProductsQuery, useGetCustomerProductsUnitMapQuery, useGetStandardProductsQuery } from '../../persistence/productApiSlice.ts';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../persistence/authSlice.ts';
import { Language } from '../../persistence/model/Common.ts';


const getQuantityFromCoef = (product: StandardProduct) => {
  return !!product.coefficient ? Math.round(((product.availableQuantity / product.coefficient) * 100) / 100)  : product.availableQuantity
}

export const useProductSelectorOptionsMapper = (order: DetailedOrder, orderType: OrderType): { isFetching: boolean; data: ProductTreeSelectGroupOption[] } => {
  const intl = useIntl();
  const locale = useSelector(localeSelector);
  const customProductsQuery = useGetActiveCustomProductsQuery(order.customerId, { skip: !orderType || orderType !== OrderType.DEFAULT });
  const standardProductsQuery = useGetStandardProductsQuery(order.customerId, { skip: !orderType || orderType !== OrderType.STANDARD });
  const productUnitMapQuery = useGetCustomerProductsUnitMapQuery(order.customerId, {skip: !orderType || orderType !== OrderType.DEFAULT})

  const isEt = locale === Language.ET;

  return useMemo(
    () =>
      orderType === OrderType.STANDARD
        ? {
            ...standardProductsQuery,
            data: standardProductsQuery.data?.data.map(
              (row, index) =>
                ({
                  value: `group-${index}`,
                  label: isEt ? row.groupName : row.groupNameEN,
                  selectable: false,
                  isGroup: true,
                  children:
                    row.products?.map(
                      (pr) =>
                        ({
                          value: pr.productId,
                          name: isEt ? pr.itemName : pr.itemNameEN,
                          ean: pr.ean,
                          externalItemId: pr.externalItemId,
                          code: pr.itemCode,
                          searchValue: `${pr.itemCode}|${pr.itemName}`,
                          availableQuantity: getQuantityFromCoef(pr),
                          groupCode: pr.itemGroupId,
                          units: [pr.customSalesUnit ?? pr.inventUnit],
                          defaultUnit: pr.customSalesUnit ?? pr.inventUnit,
                          label: (
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '15px' }}>
                              <span>
                                {pr.itemCode} - {isEt ? pr.itemName : pr.itemNameEN}
                              </span>
                              <span>
                                {translate(intl, 'order.form.standardProductName', {
                                  quantity: formatPortalNumber(getQuantityFromCoef(pr)),
                                  unit: translateUnit(intl, pr.customSalesUnit ?? pr.inventUnit),
                                })}
                              </span>
                            </div>
                          ),
                        }) as ProductTreeSelectGroupOption
                    ) || [],
                }) as ProductTreeSelectGroupOption
            ),
          }
        : {
            ...customProductsQuery,
            data: customProductsQuery.data?.data
              .filter((p) => p.active)
              .map(
                (p) =>
                  ({
                    value: p.id,
                    name: p.name,
                    ean: p.ean,
                    externalItemId: p.externalItemId,
                    label: `${p.code} - ${p.name}`,
                    searchValue: `${p.code}|${p.name}`,
                    groupCode: p.groupCode,
                    isGroup: false,
                    preferredUnit: p.preferredUnit,
                    productGroup: p.productGroup,
                    units: productUnitMapQuery?.data?.data.find(
                      (unitEntry) => unitEntry.productId === p.id)?.units || [p.preferredUnit]
                  }) as ProductTreeSelectGroupOption
              ),
          },
    [customProductsQuery, intl, isEt, orderType, standardProductsQuery]
  );
};
