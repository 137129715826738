import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { translate, translateUnit } from '../../../translations/TranslationUtils.ts';
import { formatDate } from '../../../util/DateUtil.ts';
import { ColumnType } from 'antd/es/table';
import { PurchaseOrderProduct } from '../../../persistence/model/Order.ts';
import { Table } from 'antd';
import { useGetPurchaseOrderProductsQuery } from '../../../persistence/orderApiSlice.ts';
import { formatPortalNumber } from '../../../util/NumberUtil.ts';
import dayjs from 'dayjs';
import ProductRenderer from '../../product/ProductRenderer.tsx';

type OrderOrderedRowsProps = {
  purchaseOrderId: number;
};

const OrderOrderedRows = ({ purchaseOrderId }: OrderOrderedRowsProps): JSX.Element => {
  const intl = useIntl();
  const { data, isLoading } = useGetPurchaseOrderProductsQuery(purchaseOrderId);

  const columns = useMemo(
    () =>
      [
        {
          key: 'item',
          title: translate(intl, 'order.details.quotationRows.product'),
          dataIndex: 'productName',
          render: (productName, { itemId, externalItemId, ean }) => (
            <ProductRenderer productNumber={itemId} productName={productName} externalItemId={externalItemId} ean={ean} />
          ),
        },
        {
          key: 'deliveryDate',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.deliveryDate'),
          dataIndex: 'deliveryDate',
          render: (value) => formatDate(intl, dayjs(value)),
        },
        {
          key: 'quantity',
          width: 200,
          title: translate(intl, 'order.details.quotationRows.quantity'),
          dataIndex: 'quantity',
          render: (value, { unit }) => `${formatPortalNumber(value || 0)} ${translateUnit(intl, unit)}`,
        },
      ] as ColumnType<PurchaseOrderProduct>[],
    [intl]
  );

  return <Table<PurchaseOrderProduct> loading={isLoading} columns={columns} dataSource={data?.data || []} rowKey="id" pagination={false} />;
};

export default OrderOrderedRows;
