import { apiSlice } from './apiSlice.ts';
import { AppSettingRequest, AppSettingType, BannerSettingsResponse, ContractTermsResponse } from './model/AppSetting.ts';
import { CommonApiResponse } from './model/Common.ts';

export const appSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    saveContractTerm: builder.mutation<void, AppSettingRequest<AppSettingType.CONTRACT_TERM>>({
      query: (body) => ({
        url: `api/app-settings/contract-terms/save`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ApplicationSettings'],
    }),
    saveBannerSettings: builder.mutation<void, AppSettingRequest<AppSettingType.BANNER>>({
      query: (body) => ({
        url: `api/app-settings/banner-settings/save`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ApplicationSettings'],
    }),
    getContractTerms: builder.query<CommonApiResponse<ContractTermsResponse>, void>({
      query: () => ({
        url: `api/app-settings/contract-terms`,
        method: 'GET',
      }),
      providesTags: ['ApplicationSettings'],
    }),
    getBannerSettings: builder.query<CommonApiResponse<BannerSettingsResponse>, void>({
      query: () => ({
        url: `api/app-settings/banner-settings`,
        method: 'GET',
      }),
      providesTags: ['ApplicationSettings'],
    }),
  }),
});

export const {
  useSaveContractTermMutation,
  useSaveBannerSettingsMutation,
  useGetContractTermsQuery,
  useGetBannerSettingsQuery,
} = appSettingsApiSlice